<template>
  <div>
    <v-row dense class="mt-3">
      <v-col xs="12" md="3" lg="2">
        <v-select dense label="Город" :menu-props="{ maxHeight: 550 }" v-model="filters.cityId" :items="origin == 'https://app.a-prokat.com' ||
          origin == 'http://app.a-prokat.com'
          ? allCities.filter((x) => x.id != 696)
          : allCities
          " @change="changeCity" item-text="name" item-value="id" outlined no-data-text="Нет совпадений">
        </v-select>
      </v-col>

      <v-col xs="12" md="3" lg="2" class="mr-1">
        <v-menu ref="dateAtMenu" v-model="dateAtMenu" :close-on-content-click="true" :nudge-right="40"
          :return-value.sync="filters.dateAt" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense v-model="dateTextAt" label="Начало аренды" prepend-icon="mdi-calendar-month" readonly
              v-bind="attrs" v-on="on" outlined :disabled="!filters.cityId"></v-text-field>
          </template>
          <v-date-picker :min="minMaxDate('dateAt')" show-adjacent-months="true" first-day-of-week="1" no-title
            v-if="dateAtMenu" v-model="filters.dateAt" full-width @click:date="$refs.dateAtMenu.save(filters.dateAt)"
            @change="getCars"></v-date-picker>
        </v-menu>
      </v-col>

      <!-- Убрал конец аренды до -->
      <!-- <v-col v-if="isLarge" xs="12" md="3" lg="2" class="mr-1">
        <v-menu
          ref="dateToMenu"
          v-model="dateToMenu"
          :close-on-content-click="true"
          :nudge-right="40"
          :return-value.sync="filters.secondDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              v-model="dateTextTo"
              label="Конец аренды"
              prepend-icon="mdi-calendar-month"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              :disabled="!filters.cityId"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="minMaxDate('dateAt')"
            show-adjacent-months="true"
            first-day-of-week="1"
            no-title
            v-if="dateToMenu"
            v-model="filters.secondDate"
            full-width
            @click:date="$refs.dateToMenu.save(filters.secondDate)"
            @change="getCars"
          ></v-date-picker>
        </v-menu>
      </v-col> -->

      <!-- <v-col xs="12" md="3" lg="2">
                <v-select
                dense
                :items="statuses"
                item-text="name"
                item-value="id"
                label="Статус"
                v-model="filters.statuses"
                required
                outlined
                multiple
                ></v-select>
            </v-col> -->

      <!-- <v-col v-if="filters.cityId && !loading" xs="12" md="1" lg="1">
        <v-btn
          @click="getCars"
          outlined
          :disabled="!filters.cityId"
          aria-label="refresh-cars"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col> -->
      <v-col v-if="filters.cityId && !loading && !isMobile" xs="12" md="2" lg="2">
        <v-btn @click="showFiltersPC" outlined>{{ showFilterOnScreen ? 'Скрыть' : 'Показать' }}<br />
          фильтры</v-btn>
      </v-col>

      <v-col v-if="filters.cityId && !loading && !isMobile" xs="12" md="1" lg="1">
        <v-btn @click="clearFilters" outlined :disabled="!filters.price &&
          !filters.tranmissions.length &&
          !filters.carClasses.length &&
          !filters.fuels.length &&
          !filters.carAdres.length
          ">Сбросить <br />
          фильтры</v-btn>
      </v-col>
    </v-row>
    <v-container v-if="showSwitcher" :class="isMobile ? 'switcherMobile' : 'switcherDesktop'">
      <v-radio-group v-model="showCars" row class="my-auto">
        <div class="mr-3">Я ищу</div>
        <v-radio v-for="n in options" :key="n" :label="n" :value="n" @change="goToApartments"></v-radio>
      </v-radio-group>
    </v-container>
    <!-- <v-card v-if="showFilters" class="mx-1"> -->

    <v-dialog v-model="filters.visible" class="overflow-hidden" v-if="isMobile" persistent fullscreen hide-overlay>
      <FilterComponent />
    </v-dialog>

    <v-row v-if="filters.cityId && !loading && !isMobile" :class="showFilterOnScreen ? 'showFilter' : 'hideFilter'">
      <v-col xs="12" md="2" lg="2" sm="3" xl="2">
        <v-text-field dense label="Цена до" outlined type="number" v-model="filters.price"></v-text-field>
      </v-col>

      <v-col xs="12" md="2" lg="2" sm="3" xl="2" v-if="carAdres.length > 1 && !bookingState.hideFilials">
        <v-select dense :items="carAdres" item-text="recordTitle" item-value="recordId" label="Адрес выдачи"
          v-model="filters.carAdres" outlined multiple required></v-select>
      </v-col>

      <v-col xs="12" md="2" lg="2" sm="3" xl="2">
        <v-select dense :items="tranmissions" v-model="filters.tranmissions" label="Коробка" required outlined
          multiple></v-select>
      </v-col>
      <v-col xs="12" md="2" lg="2" sm="3" xl="2">
        <v-select dense :items="seatCount" label="Число мест" v-model="filters.seatCount" required outlined
          multiple></v-select>
      </v-col>

      <v-col xs="12" md="2" lg="2" sm="3" xl="2">
        <v-autocomplete dense :items="models" label="Модель" v-model="filters.models" required outlined
          multiple></v-autocomplete>
      </v-col>
      <!-- <v-col xs="12" md="3" lg="2">
                    <v-select
                    dense
                    :items="['Седан','Универсал', 'Джип']"
                    label="Кузов"
                    required
                    outlined
                    ></v-select>
                </v-col> -->

      <v-col xs="12" md="2" lg="2" sm="3">
        <v-select dense :items="carClasses" label="Класс" v-model="filters.carClasses" required outlined
          multiple></v-select>
      </v-col>

      <!-- <v-col xs="12" md="3" lg="2">
                    <v-select
                    dense
                    :items="models"
                    label="Модель"
                    v-model="filters.models"
                    required
                    outlined
                    multiple
                    ></v-select>
                </v-col> -->

      <v-col xs="12" md="2" lg="2" sm="3" class="pr-10">
        <v-select dense :items="fuels" label="Топливо" v-model="filters.fuels" required outlined multiple></v-select>
      </v-col>

      <!-- <v-col xs="12" md="3" lg="2">
                <v-menu
                    ref="carProductYearMenu"
                    v-model="carProductYearMenu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    :return-value.sync="filters.carProductYear"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        dense
                        v-model="filters.carProductYear"
                        label="Год выпуска от"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        :min="minMaxDate('carProductYear')"
                        :max="minMaxDate('dateAt')"
                        type="year"
                        first-day-of-week="1"
                        locale="ru-Latn"
                        no-title
                        v-if="carProductYearMenu"
                        v-model="filters.carProductYear"
                        full-width
                        @click:date="$refs.carProductYearMenu.save(filters.carProductYear)"
                    ></v-date-picker>
                </v-menu>
                </v-col> -->
    </v-row>
    <!-- </v-card> -->

    <v-layout row wrap>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="50"></v-progress-circular>
      </v-overlay>

      <h2 v-if="!allCars.length && filters.cityId && !loading">
        {{ noCarsText }}
      </h2>
      <p v-if="!allCars.length && filters.cityId && !loading">
        Попробуйте изменить настройки фильтра или посмотреть авто на другие даты
      </p>
      <v-flex v-for="car in allCars" :key="car.id">
        <CarsCard :car="car" class="mb-2 center-webkit"></CarsCard>
      </v-flex>
      <v-flex class="mb-2 center-webkit" v-if="!loading">
        <Promo promoText="Рекомендуй нас" promoSubText="и зарабатывай до 1000 руб. с каждой заявки"
          confirmText="Хотите рекомендовать нас другим и получать до 1000 руб. с каждой заявки?" :fontSize="18"
          buttonText="Регистрация" :goToPromo="goToReferal" typeOfPromo="referal"
          imageLink="https://thumbs.dreamstime.com/b/%D1%81%D1%82%D0%B5%D0%BA-%D1%81%D0%BE%D0%B1%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D1%81%D1%87%D0%B0%D1%81%D1%82%D0%BB%D0%B8%D0%B2%D0%BE%D0%B3%D0%BE-%D1%8E%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BC%D0%B0%D0%BB%D1%8C%D1%87%D0%B8%D0%BA%D0%B0-%D1%81%D1%83%D0%BC%D0%B0%D1%81%D1%88%D0%B5%D0%B4%D1%88%D0%B8%D0%B9-%D0%BE%D1%80%D0%B5%D1%82-157153222.jpg" />
      </v-flex>
      <v-flex class="mb-2 center-webkit" v-if="!loading">
        <Promo promoText="Сдайте авто в аренду" promoSubText="и зарабатывайте до 100 000р. в месяц с одного автомобиля!"
          confirmText="Вы хотите сдать свой авто в аренду, либо инвевстировать?" :fontSize="18" buttonText="Сдать машину"
          :goToPromo="goToInvestCar" :clickMorePromo="goToOwnersMoreInfo" typeOfPromo="car"
          imageLink="https://a-prokat.storage.yandexcloud.net/1/ee80bffa-0457-4859-8e14-c11a2a93c6f5/9c220098-ebf9-4114-bd37-2e4a7ac1d96e.png" />
      </v-flex>
      <v-flex class="mb-2 center-webkit" v-if="!loading">
        <Promo promoText="Откройте автопрокат с окупаемостью от 3 месяцев!" confirmText="Хотите открыть филиал А-прокат?"
          :fontSize="20" buttonText="Купить франшизу" :clickMorePromo="goToPartnersMoreInfo" :goToPromo="goToFranchCar"
          typeOfPromo="carFranch"
          imageLink="https://a-prokat.storage.yandexcloud.net/1/1d7478c5-b9ad-4394-9444-d0dfa9411622/c59018ab-5e84-420a-a17e-98f76ffe6086.png" />
      </v-flex>
      <!-- <h2 v-if="!filters.cityId">Выберите город</h2> -->

      <v-row justify="center" overflow-hidden v-if="showPopUp">
        <v-dialog v-model="showPopUp" class="overflow-hidden" persistent fullscreen hide-overlay>
          <v-card class="text-center">
            <div class="pa-10">
              {{ successPromoText }}
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="text-center" color="primary" @click="clickHorosho">
                Хорошо
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <div class="btn-sticky" :class="{ 'btn-sticky--bottom': windowAtBottom }">
        <v-fab-transition class="mx-12">
          <v-btn fab x-small width="110" height="30" rounded color="primary"
            v-if="isMobile && this.filters.cityId && !loading" @click="showFilterClick">
            <div v-if="amountOfChosenFilters" class="amountOfChosenFilters">
              <h3>{{ amountOfChosenFilters }}</h3>
            </div>
            <v-icon>mdi-format-list-bulleted</v-icon>Фильтры
          </v-btn>
        </v-fab-transition>
      </div>
    </v-layout>
    <v-row justify="center" overflow-hidden>
      <v-dialog v-if="user.auth === true &&
        bookingState.step != 404 &&
        user.whatsappIsDefaultChannel
        " v-model="bookingState.booking" class="overflow-hidden" persistent fullscreen hide-overlay>
        <v-card v-if="user.auth === true &&
          bookingState.step != 404 &&
          user.whatsappIsDefaultChannel
          " height="800">
          <Booking />
        </v-card>
        <!-- <v-card
          v-if="
            user.auth === false ||
              (user.auth === true && !user.whatsappIsDefaultChannel)
          "
          class="d-flex align-center"
        >
          <Phone
            :sendPhone="startBooking"

            @goBackFunction="updateBookingState"
          />
        </v-card> -->
      </v-dialog>
    </v-row>
    <v-row justify="center" overflow-hidden>
      <v-dialog v-model="unauthuser" class="overflow-hidden test-test" persistent max-width="350" overlay-color="black"
        overlay-opacity="0.4">
        <v-card class="d-flex align-center">
          <Phone :sendPhone="startBooking" :btnText="car.id ? 'забронировать' : 'посмотреть авто'" :text="car.id
            ? 'Для продолжения бронирования введите номер телефона, к которому привязан Whatsappa:'
            : 'Для продолжения просмотра введите номер телефона, к которому привязан Whatsapp:'
            " @goBackFunction="backOnMain" />
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Booking from '../components/Booking';
import Phone from '../components/Phone';
import FilterComponent from '../components/FilterComponent';
import CarsCard from '@/components/CarsCard';
import Promo from '../components/Promo.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { find } from 'lodash';
export default {
  components: {
    CarsCard,
    Booking,
    Phone,
    FilterComponent,
    Promo,
  },
  metaInfo() {
    return {
      title: 'Подбор авто',
      // meta: [
      //     { property: 'og:title', content: this.car.model },
      //     { property: 'og:description', content: this.car.description },
      //     { property: 'og:image', content: this.car.photos[0] },
      // ]
    };
  },
  data: () => ({
    timer: 0,
    loading: false,
    dateAtMenu: false,
    dateToMenu: false,
    options: ['Авто', 'Квартиру'],
    showCars: 'Авто',
    noCarsText: '',
    isMobile: false,
    isLarge: false,
    showFilterMobile: false,
    windowAtBottom: false,
    origin: window.origin,
    showPopUp: false,
    disableCar: false,
    disableInvest: false,
    showFilterOnScreen: true,
    successPromoText:
      'Ваша заявка находится в обработке, в скором времени с Вами свяжется специалист.',
    // showFilters: true,
    // carProductYearMenu: false,
    // filters: {
    //     city: null,
    //     dateAt: null,
    //     statuses: null,
    //     price: null,
    //     tranmissions: null,
    //     classes: null,
    //     models: null,
    //     fuels: null,
    //     carProductYear: null
    // }
  }),
  beforeDestroy() {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  computed: {
    ...mapGetters([
      'allCities',
      'allCars',
      'car',
      'filters',
      'statuses',
      'tranmissions',
      'models',
      'seatCount',
      'fuels',
      'carAdres',
      'carClasses',
      'bookingState',
      'user',
      'allFilials',
      'currentCityId',
      // 'globalTest',
    ]),
    unauthuser() {
      let flag = false;
      if (this.filters.cityId && !this.user.auth) {
        const cityRecord = this.allCities.find(
          (x) => x.id == this.filters.cityId
        );
        console.log('build is working');

        console.log(cityRecord)
        if (cityRecord /*&& this.timer > 10*/) {
          flag = cityRecord.authRequired;
        }
        console.log(flag)
        if (flag || this.car.id) {
          flag =
            this.user.auth === false ||
            (this.user.auth === true && !this.user.whatsappIsDefaultChannel);
        }
      }
      return flag;
      // return !this.user.auth && this.globalTest
    },
    showSwitcher() {
      let flag = false;
      let filialsFromCtiy = this.allFilials.filter(
        (x) => x.cityId == this.currentCityId && x.hasApartment
      );
      flag = !!filialsFromCtiy.length;
      return flag;
    },
    amountOfChosenFilters() {
      let numberToReturn;
      numberToReturn =
        ((!this.bookingState.hideFilials && +!!this.filters.carAdres.length) ||
          0) +
        +!!this.filters.carClasses.length +
        +!!this.filters.seatCount.length +
        +!!this.filters.models.length +
        +!!this.filters.tranmissions.length +
        +!!this.filters.fuels.length +
        +!!this.filters.price;
      return numberToReturn;
    },
    showFiltersText() {
      if (!this.showFilters) return 'Показать фильтры';
      else return 'Скрыть фильтры';
    },
    dateTextAt() {
      return moment(this.filters.dateAt).format('DD.MM.YYYY');
    },
    dateTextTo() {
      if (this.filters.secondDate)
        return moment(this.filters.secondDate).format('DD.MM.YYYY');
      return 'Не указана';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.name;
    });
  },
  methods: {
    backOnMain() {
      //this.unauthuser=false;
      return this.$router.push('/');
    },
    goToOwnersMoreInfo() {
      return window.open('https://a-prokat.com/rentacars', '_blank').focus();
    },
    goToPartnersMoreInfo() {
      return window.open('https://a-prokat.com/franchise', '_blank').focus();
      // return window.open('https://a-prokat.com/franchisee', '_blank').focus();
    },
    showFiltersPC() {
      this.showFilterOnScreen = !this.showFilterOnScreen;
    },
    clickHorosho() {
      this.showPopUp = false;
    },
    async goToInvestCar() {
      await this.createInterestCar(true);
      this.successPromoText =
        'Ваша заявка находится в обработке, в скором времени с Вами свяжется специалист.';
      this.showPopUp = true;
    },
    async goToFranchCar() {
      await this.createInterestCar(false);
      this.successPromoText =
        'Ваша заявка находится в обработке, в скором времени с Вами свяжется специалист.';
      this.showPopUp = true;
    },
    async goToReferal() {
      this.loading = true;
      await this.createReferalBooking(true);
      this.loading = false;
      this.successPromoText =
        'Ваша заявка находится в обработке, в скором времени с Вами свяжется специалист. Мы уже выслали в ватсапе ссылку на Ваш личный кабинет.';
      if (this.user.id) {
        this.$router.push('/referals');
      } else this.showPopUp = true;
    },
    goToApartments() {
      const city = this.$route.params.city;
      const date = this.$route.query.dateAt || this.filters.dateAt;
      // const params = this.$route
      this.$router.push(`/apartments/${city}?dateAt=${date}`);
    },
    showFilterClick() {
      this.filters.visible = true;
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.isLarge = window.innerWidth > 1264;
    },
    minMaxDate(type) {
      if (type == 'dateAt') return moment().format('YYYY-MM-DD');
      if (type === 'carProductYear') return '1980-01-01';
    },
    ...mapActions([
      'getCitites',
      'getCarsByCityId',
      'getUTM',
      'getCarById',
      'getBooking',
      'createInterestCar',
      'startBooking',
      'createReferalBooking',
      'checkAndGetUTM'
    ]),
    ...mapMutations([
      'clearFilter',
      'clearCars',
      'changeCurrentCityId',
      'updateBookingState',
      "updateCar"
    ]),
    clearFilters() {
      this.clearFilter({ filialStay: this.bookingState.hideFilials });
    },
    async changeCity(id) {
      this.changeCurrentCityId(id);
      this.clearFilters();
      await this.getCars();
    },
    async getCars() {
      this.loading = true;
      if (this.previousRoute !== 'car') this.clearFilters();
      // if (
      //   this.$route.fullPath !=
      //     `/cars?cityId=${this.filters.cityId}&dateAt=${this.filters.dateAt}` &&
      //   this.filters.cityId
      // )
      //   this.$router.replace(
      //     `/cars?cityId=${this.filters.cityId}&dateAt=${this.filters.dateAt}`
      //   );
      let cityFlitered = find(this.allCities, (o) => {
        return o.id === this.filters.cityId;
      });
      if (
        this.$route.fullPath !=
        `/cars/${cityFlitered.nameTranslite}?dateAt=${this.filters.dateAt}` &&
        this.filters.cityId
      )
        this.$router.replace(
          `/cars/${cityFlitered.nameTranslite}?dateAt=${this.filters.dateAt}`
        );
      if (this.filters.cityId)
        this.noCarsText = `К сожалению в городе ${find(this.allCities, (o) => {
          return o.id == this.filters.cityId;
        })?.name
          } на ${moment(this.filters.dateAt).format(
            'DD.MM.YYYY'
          )} нет свободных авто😞`;
      console.log("this.bookingState.cityLink");
      console.log(this.bookingState.cityLink);
      await this.getCarsByCityId({
        cityId: this.filters.cityId,
        dateAt: this.filters.dateAt,
        secondDate: this.filters.secondDate,
        haveFilials: this.bookingState.cityLink && this.bookingState.cityLink.length ? true : false
      });
      console.log("this.bookingState.cityLink2");
      console.log(this.bookingState.cityLink);
      this.loading = false;
    },
  },
  async mounted() {
    await this.checkAndGetUTM(this.$route.query)

    this.updateCar();
    setInterval(() => {
      this.timer += 1;
    }, 1000);
    this.loading = true;
    // if (!this.bookingState.booking) await this.getBooking();
    this.onResize();
    (window.onscroll = () => {
      if (window.scrollY > 900 && this.isMobile) {
        this.timer = 11;
      }
      if (window.scrollY > 300 && !this.isMobile) {
        this.timer = 11;
      }
      if (
        window.scrollY + window.innerHeight + 20 >=
        window.document.body.offsetHeight
      ) {
        this.windowAtBottom = true;
      } else this.windowAtBottom = false;
    }),
      window.addEventListener('resize', this.onResize, { passive: true });
    if (!this.allCities.length > 0) await this.getCitites();
    let { city } = this.$route.params;
    if (city) city = city.indexOf('?') && city.split('?')[0];
    if (this.$route.query.carId) {
      await this.getCarById(this.$route.query.carId);
      city = this.car.carCityId;
    }
    if (this.bookingState.cityId) {
      city = this.bookingState.cityId;
    }
    // if (
    //   (this.$route.query.cityId &&
    //     find(this.allCities, (o) => {
    //       return o.id === this.$route.query.cityId;
    //     })) ||
    //   this.$route.query.cityId == "696"
    // ) {
    const cityFlitered = find(this.allCities, (o) => {
      return o.nameTranslite === city || o.id == city;
    });

    // конструкция if (!(this.allCars[0] && this.filters.cityId == this.allCars[0].carCityId))
    // проверяет надо ли заново искать все машины, теряем в динамичности отображения
    // занятости-свободности, но зато без загрузок когда туда-сюда тыкаем машины

    if (city && cityFlitered) {
      this.filters.cityId = cityFlitered.id;
      if (this.$route.query.dateAt)
        this.filters.dateAt = this.$route.query.dateAt;
      if (
        !(this.allCars[0] && this.filters.cityId == this.allCars[0].carCityId)
      )
        await this.getCars();
      this.loading = false;
    }
    if (city === 'yasnii_(_test)' || city == 696) {
      this.filters.cityId = '696';
      if (
        !(this.allCars[0] && this.filters.cityId == this.allCars[0].carCityId)
      )
        await this.getCars();
      this.loading = false;
      // await this.getUTM(this.$route.query);
    }

    const filialFiltered = this.bookingState.filialToShow;
    if (filialFiltered) {
      this.filters.carAdres = [filialFiltered.title];
    }
    if (city && !cityFlitered) this.$router.push('/404');
    if (cityFlitered) this.changeCurrentCityId(cityFlitered.id);
    if (!city) {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss" scoped>
.center-webkit {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.btn-sticky {
  position: fixed;
  bottom: 0.5rem;
  left: calc(50% - 55px);
  transition-property: bottom;
  transition-duration: 500ms;
}

.btn-sticky--bottom {
  bottom: 7rem;
}

.amountOfChosenFilters {
  z-index: 1;
  position: absolute;
  left: 8px;
  top: -3px;
  border-radius: 100%;
  border: 1px solid;
  width: 15px;
  height: 15px;
  line-height: 13.5px;
  background-color: #db1912;
}

// ну да, по-хорошему надо --mobile и все
.switcherMobile {
  display: flex;
  justify-content: center;
}

.switcherDesktop {
  display: block;
  margin: 0px;
}

.hideFilter {
  // display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s ease-out;
}

.showFilter {
  // display: block;
  opacity: 1;
  height: auto;
  transition: opacity 0.5s ease-in;
  overflow: auto;
}
</style>
