<template>
  <div class="cars mr-2 mb-10">
    <v-card width="370" height="390" hover>
      <div class="headContainer">
        <v-card-title
          class="promoTitle"
          :style="fontSize && `font-size: ${fontSize}px`"
          >{{ promoText }}
        </v-card-title>
        <v-card-subtitle v-if="promoSubText">{{
          promoSubText
        }}</v-card-subtitle>
      </div>
      <v-img height="250" :src="imageLink"></v-img>
      <v-card-actions>
        <v-btn @click="clickMorePromo"
          ><v-icon>mdi-alpha-i-circle</v-icon> <span>Подробнее</span></v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          text
          @click="toggleShowAreYouSure"
          :disabled="btnDisabled"
          :loading="loader"
          ><v-icon>mdi-check-bold</v-icon> <span>{{ buttonText }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showAreYouSure" width="380">
      <v-card max-width="380" shaped class="pt-8 pb-3" elevation="5" outlined>
        <v-card-text class="text-body-1 text-center">
          {{ confirmText }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="toggleShowAreYouSure">нет</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="openDialogOrCreateBooking">да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center" overflow-hidden>
      <v-dialog
        v-model="showDialog"
        class="overflow-hidden"
        persistent
        fullscreen
        hide-overlay
      >
        <v-card
          v-if="
            user.auth === false ||
              (user.auth === true && !user.whatsappIsDefaultChannel)
          "
          class="d-flex align-center"
        >
          <PhoneApartments
            :booking="goToPromo"
            :goBack="goBack"
            :btnText="buttonText"
          />
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import PhoneApartments from '../views/apartments/PhoneApartments.vue';
export default {
  components: {
    PhoneApartments,
  },
  props: {
    promoText: String,
    promoSubText: String,
    confirmText: String,
    fontSize: Number,
    buttonText: String,
    imageLink: String,
    goToPromo: Function,
    openDialog: Function,
    clickMorePromo: {
      type: Function,
      default: () => {
        return console.log('more');
        // return window.open('https://owners.hutsharing.com', '_blank').focus();
      },
    },
    typeOfPromo: {
      type: String,
      // default: 'car',
    },
  },
  data() {
    return {
      metaInfo: null,
      showDialog: false,
      loader: false,
      disableBtn: false,
      showAreYouSure: false,
    };
  },
  computed: {
    ...mapGetters([
      'car',
      'bookingState',
      'bookingApartment',
      'user',
      'currentFilial',
      'allCities',
    ]),
    btnDisabled() {
      if (this.typeOfPromo == 'car') {
        return this.bookingState.carInteres;
      }
      if (this.typeOfPromo == 'referal') {
        return this.bookingState.referalInteres;
      }
      if (this.typeOfPromo == 'apartment') {
        return this.bookingApartment.apartmentInteres;
      }
      if (this.typeOfPromo == 'carFranch') {
        return this.bookingState.franchInteres;
      }
      if (this.typeOfPromo == 'apartmentFranch') {
        return this.bookingApartment.franchInteres;
      }

      return false;
    },
  },
  methods: {
    ...mapActions([
      'getCarById',
      'reserveCar',
      'startBooking',
      'getUTM',
      'findFilialById',
    ]),
    ...mapMutations([
      'updateBookingState',
      'updateStep',
      'adaptCarVideo',
      // 'updateCarInteres',
      // 'updateFranchCarInteres',
      // 'updateApartmentInteres',
      // 'updateFranchApartmentInteres',
    ]),
    async openDialogOrCreateBooking() {
      this.toggleShowAreYouSure();
      if (this.user.phone) {
        this.loader = true;
        const data = await this.goToPromo();
        this.loader = false;
        this.disableBtn = true;
        return data;
      } else this.showDialog = true;
    },
    goBack() {
      this.showDialog = false;
    },
    toggleShowAreYouSure() {
      this.showAreYouSure = !this.showAreYouSure;
    },
  },
};
</script>

<style scoped>
/* .vertical {
  margin: 40px 0;
} */
.headContainer {
  display: block;
  height: 90px;
  padding: 4px;
}
.promoTitle {
  padding-top: 4px;
  word-break: inherit;
  justify-content: space-around;
}
</style>
