<template>
  <v-card>
    <v-toolbar>
      <v-btn
        @click="clearFilters"
        text
        color="primary"
        class="mr-12"
        x-small
        :disabled="
          !filters.price &&
            !filters.tranmissions.length &&
            !filters.carClasses.length &&
            !filters.fuels.length &&
            !filters.carAdres.length
        "
        >Сбросить <br />все</v-btn
      >

      <v-toolbar-title>Фильтры</v-toolbar-title>
      <!-- Кнопка "закрыть" убрана из-за конфликта с кнопкой поддержки -->
      <!-- <v-spacer></v-spacer>
      <v-btn icon plain @click="hideOverlay">
        <v-icon color="red">mdi-close</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-btn
      fab
      small
      bottom
      fixed
      block
      rounded
      @click="hideOverlay"
      :disabled="this.allCars.length === 0"
      color="primary"
    >
      {{ changeText() }}</v-btn
    >

    <v-row>
      <v-col class="mx-6">
        <v-text-field
          dense
          label="Цена до"
          outlined
          type="number"
          v-model="filters.price"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-6">
        <v-select
          dense
          :items="tranmissions"
          v-model="filters.tranmissions"
          label="Коробка"
          required
          outlined
          multiple
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="carAdres.length > 1 && showFilials">
      <v-col class="mx-6">
        <v-select
          dense
          :items="carAdres"
          item-text="recordTitle"
          item-value="recordId"
          v-model="filters.carAdres"
          label="Адрес выдачи"
          outlined
          multiple
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col xs="12" md="3" lg="2"> 
                    <v-select
                    dense
                    :items="['Седан','Универсал', 'Джип']"
                    label="Кузов"
                    required
                    outlined
                    ></v-select>
                </v-col> -->

      <v-col class="mx-6">
        <v-select
          dense
          :items="carClasses"
          label="Класс"
          v-model="filters.carClasses"
          required
          outlined
          multiple
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-6">
        <v-select
          dense
          :items="fuels"
          label="Топливо"
          v-model="filters.fuels"
          required
          outlined
          multiple
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-6">
        <v-autocomplete
          dense
          :items="models"
          label="Модель"
          v-model="filters.models"
          required
          outlined
          multiple
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mx-6">
        <v-select
          dense
          :items="seatCount"
          label="Число мест"
          v-model="filters.seatCount"
          required
          outlined
          multiple
        ></v-select>
      </v-col>
      <!-- <v-col xs="12" md="3" lg="2">
                <v-menu
                    ref="carProductYearMenu"
                    v-model="carProductYearMenu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    :return-value.sync="filters.carProductYear"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        dense
                        v-model="filters.carProductYear"
                        label="Год выпуска от"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        :min="minMaxDate('carProductYear')"
                        :max="minMaxDate('dateAt')"
                        type="year"
                        first-day-of-week="1"
                        locale="ru-Latn"
                        no-title
                        v-if="carProductYearMenu"
                        v-model="filters.carProductYear"
                        full-width
                        @click:date="$refs.carProductYearMenu.save(filters.carProductYear)"
                    ></v-date-picker>
                </v-menu>
                </v-col> -->
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { find } from 'lodash';
export default {
  data: () => ({
    loading: false,
    btnText: 'авто',
  }),
  computed: {
    ...mapGetters([
      'allCities',
      'allCars',
      'filters',
      'statuses',
      'tranmissions',
      'models',
      'fuels',
      'carClasses',
      'carAdres',
      'bookingState',
      'user',
      'seatCount',
    ]),
    showFilials() {
      return !this.bookingState.hideFilials;
    },
    // getAdress() {
    //   console.log('get adress')
    //   return JSON.stringi
    // }
  },
  methods: {
    changeText() {
      if (this.allCars.length === 0)
        return (this.btnText = `Автомобилей не найдено`);
      if (this.allCars.length === 1)
        return (this.btnText = `Показать ${this.allCars.length} автомобиль`);
      if (this.allCars.length > 1 && this.allCars.length < 5)
        return (this.btnText = `Показать ${this.allCars.length} автомобиля`);
      return (this.btnText = `Показать ${this.allCars.length} автомобилей`);
    },
    hideOverlay() {
      this.filters.visible = false;
    },
    ...mapActions(['getCitites', 'getCarsByCityId']),
    ...mapMutations(['clearFilter', 'clearCars']),
    clearFilters() {
      this.clearFilter({ filialStay: this.bookingState.hideFilials });
    },
    async getCars() {
      this.loading = true;
      this.clearFilters();
      const cityFlitered = find(this.allCities, (o) => {
        return o.id === this.filters.cityId;
      }) || { nameTranslite: 'yasny', id: '696' };
      if (
        this.$route.fullPath !=
          `/cars/${cityFlitered.nameTranslite}?dateAt=${this.filters.dateAt}` &&
        this.filters.cityId
      )
        this.$router.replace(
          `/cars/${cityFlitered.nameTranslite}?dateAt=${this.filters.dateAt}`
        );
      if (this.filters.cityId)
        this.noCarsText = `К сожалению в городе ${
          find(this.allCities, (o) => {
            return o.id == this.filters.cityId;
          }).name
        } на ${moment(this.filters.dateAt).format(
          'DD.MM.YYYY'
        )} нет свободных авто😞`;
      console.log("this.filters.carAdres");
      console.log(this.filters.carAdres);
      await this.getCarsByCityId({
        cityId: this.filters.cityId,
        dateAt: this.filters.dateAt,
        secondDate: this.filters.secondDate,
        haveFilials: this.bookingState.cityLink && this.bookingState.cityLink.length ? true : false
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
